import { Workshop } from "@/types/app/workshop";

type WorkshopPriceProps = {
  workshop: Workshop;
  className?: string;
  showCurrency?: boolean;
  freeLabel?: string;
  originalPriceClassName?: string;
  discountedPriceClassName?: string;
};

export const WorkshopPrice = ({
  workshop,
  className = "",
  showCurrency = true,
  freeLabel = "FREE",
  originalPriceClassName = "text-gray-500 dark:text-gray-400 line-through",
  discountedPriceClassName = "font-medium",
}: WorkshopPriceProps) => {
  const { price } = workshop;
  
  if (!price) {
    return null;
  }
  
  const isFree = price.original_price === 0;
  const hasDiscount = price.discounted_price !== undefined && price.discounted_price < price.original_price;
  const currency = !isFree && showCurrency && price.currency ? `${getCurrencySymbol(price.currency)}` : "";
  
  const originalPriceDisplay = isFree ? freeLabel : `${price.original_price}`;
  
  return (
    <div className={`flex items-center gap-2 ${className}`}>
      {hasDiscount ? (
        <>
          <span className={originalPriceClassName}>
            {currency}{originalPriceDisplay}
          </span>
          <span className={discountedPriceClassName}>
            {currency}{price.discounted_price}
          </span>
        </>
      ) : (
        <span className={discountedPriceClassName}>
          {currency}{originalPriceDisplay}
        </span>
      )}
    </div>
  );
};

const formatCurrency = (value: number, currency: string) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(value);
}

const getCurrencySymbol = (currency: string) => {
    switch (currency) {
        case 'USD':
        return '$';
        case 'EUR':
        return '€';
        case 'GBP':
        return '£';
        default:
        return currency;
    }
}